<script>
  import { inertia } from '@inertiajs/svelte';

  export let announcement;

  const appName =  'BMN';

  function formatDate(date) {
    return new Date(date).toLocaleString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    });
  }

  function isPast(date) {
    return new Date(date) < new Date();
  }
</script>

<div class="px-4 sm:px-6 lg:px-8 py-8">
  <div class="max-w-3xl mx-auto bg-white rounded-xl shadow-lg overflow-hidden">
    <div class="bg-gradient-to-r from-red-600 to-pink-600 p-6 sm:p-8">
      <h1 class="text-3xl sm:text-4xl font-bold text-white leading-tight">Announcement Unavailable</h1>
    </div>
    <div class="p-6 sm:p-8 text-gray-800 text-base sm:text-lg">
      <p class="mb-4">The announcement "{announcement.title || 'Untitled'}" is no longer available.</p>
      {#if !announcement.is_active}
        <p class="text-gray-600">It has been deactivated.</p>
      {:else if announcement.validity && isPast(announcement.validity)}
        <p class="text-gray-600">It expired on {formatDate(announcement.validity)}.</p>
      {/if}
    </div>
    <div class="p-6 sm:p-8 bg-gray-50 border-t border-gray-200 flex justify-center">
      <a
        href="/"
        use:inertia
        class="text-blue-500 hover:text-blue-700 font-medium hover:underline"
      >
        Back to Home
      </a>
    </div>
  </div>
</div>